@font-face {
  font-family: 'SF-Pro-Text-Regular';
  src: local('SF-Pro-Text-Regular'), url(./assets/sfFont/SF-Pro-Text-Regular.otf) format('opentype');
}

body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji'; */
  font-family: 'SF-Pro-Text-Regular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.d-flex {
  display: flex;
}
.full-width {
  width: 100%;
  padding: 15px 0;
}
.half-width {
  width: 50%;
  padding: 15px 0;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
/* .ant-dropdown-menu .ant-dropdown-menu-item { */
  /* padding: unset !important; */
  /* margin: 5px 0; */
/* }  */
/* .ant-dropdown-menu .ant-dropdown-menu-item p { */
  /* padding:  10px 20px; */
  /* margin-bottom: unset !important; */
/* } */