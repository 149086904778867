$Fontcolor : #333333;

.mainContainer {
  background-color: #fff;
  height: 100%;
  padding: 40px;
}

.mainDashboard {
  height: 100%;
}
.Divider {
  margin: 17px 0 !important;
}

.imageContainer {
  height: 75px;
  margin: auto;
  word-wrap: break-word;
  text-transform: capitalize;
}
.ant-typography {
  h2 {
    font-size: 15px;
  }
}
.activeAndVerified {
  font-size: 13px;
  &:before {
    position: relative;
    content: "";
    height: 8px;
    width: 8px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
  }
}
.activate {
  color: #1FD26D;
  @extend .activeAndVerified;
  &:before {
    background-color: #1FD26D;
  }
}
.inActivateNotverified {
  color: red;
  @extend .activeAndVerified;
  &:before {
    background-color: red;
  }
}
.verified {
  @extend .activate;
  margin-left: 10px;
}
.detValue {
  font-size: 15px !important;
  color: $Fontcolor !important;
}
.sectionTitle {
  @extend .detValue;
  font-weight: bold !important;
}
.detTitle {
  font-size: 13px;
  color: #7F7F7F;
}
