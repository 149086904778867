.header {
  background: #fff;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  padding: 0px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  width: calc(100% - 230px);
  .profileButton {
    margin: 0 15px;
  }
  div {
    margin-right: 25px;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}
.bodyLayout {
  // margin-top: 64px;
}
.headerLogo {
  object-fit: contain;
  width: 100%;
  height: 64px;
  padding: 8px;
}
.headerContentContainer{
  margin-left: 230px;
  @media only screen and (max-width: 575px) {
    margin-left: 0px;
  }
}
.sider{
  @media only screen and (min-width: 575px) {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }
}

.formModalRequiredAsterisk {
  :global(.ant-form-item-label > label.ant-form-item-required::before) {
    content: "";
  }
  :global(.ant-form-item-label > label.ant-form-item-required::after) {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
  :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before){
    content: "";
  }
}

.textBlueColor {
    color: #1890ff;
}

.feedbackReviewStyle {
  object-fit: contain;
  //width: 100%;
  // height: 125px;
  // padding: 8px;
  padding: 5px 10px;
  margin: 10px 5px;
  img {
    margin: 0 5px;
    width: 16px;
  }
  font-weight: bold;
}

.feedbackRatingStyle {
  width: 32px;
}

.tagFeedbackRating {
  font-size: 21px;
  padding: 10px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: "";
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}

.rowStatusError {
  background-color: #FFF2F0;
  // &:hover {
  //   background-color: #FFF2F0 !important;    
  // }
}
// .ant-table-tbody > tr.ant-table-row.rowStatusError:hover > td {
//   background: yellow;
// }
// .ant-table-tbody > tr.ant-table-row-level-0.rowStatusError:hover{
//   //background: unset;
//   background-color: red;
// }
// .products-module {
//   background: red;
//   .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
//     background: #FFF2F0;
//   }
// }
.otp_input_style {
  border: 0.75px solid lightgray;
  width: 40px !important;
  text-align: center;
  margin: auto;
  border-radius: 3px;
  height: 30px;
  margin: 0 7px;
}



