$table-content-font-color: #333333;
$link-button-color: #0090ff;

.loginScreen {
  background: url(../../assets/login_bg_image.jpg) repeat 0 0 !important;
}
.ant-layout {
  background: unset;
}
.pageContainer {
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginContainer {
    background-color: white;
    border-radius: 4px;
    max-width: 450px;
    // min-height: 445px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 40px;
    .loginTitle {
      font-size: 30px;
      color: $table-content-font-color;
      margin-bottom: 30px;
    }
    .forgotPasswordText {
      color: #333333;
      font-size: 15px;
      margin-bottom: 20px;
    }
    .loginFormItem {
      margin-bottom: 20px;
    }
    .forgotPassword {
      text-align: right;
      color: $link-button-color;
      font-size: 14px;
      span {
        cursor: pointer;
      }
    }
    .loginButton {
      height: 50px;
      margin-top: 20px;
    }
    .loginLogo {
      width: 50%;
      margin: 12px;
    }
  }
  .otp_input_style {
    border: 0.75px solid lightgray;
    width: 40px !important;
    text-align: center;
    margin: auto;
    border-radius: 3px;
  }
}

// .leftContent {

// }
